import {navigate} from 'gatsby'
import React, {useEffect} from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import {toast, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const ReCaptchaComponent = () => {
  useEffect(() => {
    toast.error(`Too Many Requests`, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    })
  }, [])
  const handleV2Verify = async (token: any) => {
    try {
      const response = await fetch('/v2/verify_recaptcha', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          'g-recaptcha-response': token,
        }),
      })

      const data = await response.json()
      if (data.success) {
        toast.success(`reCAPTCHA verified successfully: ${data.message}`, {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
        navigate(-2)
      } else {
        toast.error(`reCAPTCHA verification failed: ${data.error}`, {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }
    } catch (error) {
      toast.error(`Error verifying reCAPTCHA: ${error}`, {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    }
  }

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ReCAPTCHA
        sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
        onChange={handleV2Verify}
      />
    </div>
  )
}

const ReCaptchaPage = () => <ReCaptchaComponent />

export default ReCaptchaPage
